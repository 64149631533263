import { ClientTemplateFormUser, ClientTemplateFormUserResponse } from './../models/ClientTemplateFormUser';
import { FormDefault } from './../models/ClientFormDefaults';
import { ApiResponse } from '../models/ApiResponse';
import { FormDefaultConfiguration } from '../models/ClientFormDefaults';
import { FormConfig, GetAllTemplatesRequest, FormTemplatePagedFilter } from '../models/Form';
import TemplateFormServiceBase from './TemplateFormServiceBase';
import { PagingFilter } from '../models/Paging';
import { RecordColumnData, RecordRowData } from '../models/Record';
import User from '../models/User';
import { ClientFormUserDto } from '../models/ClientFormUser';
class ClientTemplateFormService extends TemplateFormServiceBase {
  _clientId: string;

  constructor(clientId: string) {
    super();
    this._clientId = clientId;
  }

  //#region Builder API

  public createTemplate(data: Record<string, unknown>): Promise<ApiResponse<FormConfig>> {
    return TemplateFormServiceBase.create(`/v1/clients/${this._clientId}/template/forms`, data);
  }

  public updateTemplate(formId: string, data: Record<string, unknown>): Promise<ApiResponse<FormConfig>> {
    return TemplateFormServiceBase.update(`/v1/clients/${this._clientId}/template/forms/${formId}`, data);
  }

  public archiveTemplate(formId: string): Promise<ApiResponse<boolean>> {
    return TemplateFormServiceBase.archive(`/v1/clients/${this._clientId}/template/forms/${formId}/archive`);
  }

  public restoreTemplate(formId: string): Promise<ApiResponse<boolean>> {
    return TemplateFormServiceBase.restore(`/v1/clients/${this._clientId}/template/forms/${formId}/restore`);
  }

  public getAllTemplates(request: GetAllTemplatesRequest): Promise<ApiResponse<FormConfig[]>> {
    return TemplateFormServiceBase.getAll(`/v1/clients/${this._clientId}/template/forms`, request);
  }

  public getAllTemplatesPaged(filter: FormTemplatePagedFilter): Promise<ApiResponse<FormConfig[]>> {
    return TemplateFormServiceBase.getAllPaged(`/v1/clients/${this._clientId}/template/forms/paged`, filter);
  }

  public getFormTemplate(
    formId: string,
    version: number | undefined = undefined,
    excludeSections: boolean | undefined = undefined,
  ): Promise<ApiResponse<FormConfig>> {
    return TemplateFormServiceBase.getById(`/v1/clients/${this._clientId}/template/forms/${formId}`, formId, version, excludeSections);
  }

  //#endregion

  public getAllUsedTemplates(request: GetAllTemplatesRequest): Promise<ApiResponse<FormConfig[]>> {
    return TemplateFormServiceBase.getAll(`/v1/clients/${this._clientId}/template/forms/used`, request);
  }

  public isLinkedToClient(formId: string): Promise<ApiResponse<boolean>> {
    return TemplateFormServiceBase.get(`/v1/clients/${this._clientId}/template/forms/${formId}/is-linked-to-client`);
  }

  //#region Defaults API

  public createFormDefaults(formId: string, configuration: FormDefaultConfiguration): Promise<ApiResponse<FormDefault>> {
    return TemplateFormServiceBase.post(`/v1/clients/${this._clientId}/template/forms/${formId}/defaults`, { configuration: configuration });
  }

  //#endregion

  //#region Resource APi

  public importTemplate(formId: string): Promise<ApiResponse<FormConfig>> {
    return TemplateFormServiceBase.post(`/v1/clients/${this._clientId}/template/forms/import`, { templateFormId: formId });
  }

  public getRecordsWithAnswers(formId: string, filter: PagingFilter): Promise<ApiResponse<Record<string, RecordColumnData | string>[]>> {
    return TemplateFormServiceBase.get<RecordRowData[]>(`/v1/clients/${this._clientId}/template/forms/${formId}/answers`, {
      params: filter,
      signal: new AbortController().signal,
    }).then((res) => {
      const data = [
        ...res.data.map((x) => {
          x.columns['id'] = x.id;
          x.columns['subTitle'] = x.subTitle;
          return x.columns;
        }),
      ];
      return {
        ...res,
        data: data,
      };
    });
  }

  //#endregion

  //#region Users

  public getUsers(formId: string): Promise<ApiResponse<ClientFormUserDto[]>> {
    return TemplateFormServiceBase.get(`/v1/clients/${this._clientId}/template/forms/${formId}/users`);
  }

  public addOrUpdateUsers(formId: string, users: ClientFormUserDto[]): Promise<ApiResponse<ClientTemplateFormUser[]>> {
    return TemplateFormServiceBase.post(`/v1/clients/${this._clientId}/template/forms/${formId}/users`, users);
  }

  public removeUser(formId: string, userId: string): Promise<ApiResponse<ClientTemplateFormUser[]>> {
    return TemplateFormServiceBase.delete(`/v1/clients/${this._clientId}/template/forms/${formId}/users`, { params: { userId: userId } });
  }

  public getCurrentResourceTemplateUser(formTemplateUsers: ClientTemplateFormUser[], currentUser: User): ClientTemplateFormUser | null {
    return formTemplateUsers.find((user) => user.id === currentUser.id) || null;
  }

  //#endregion
}

export default ClientTemplateFormService;
